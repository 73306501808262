import $ from "cash-dom";
import UIkit from 'uikit';

import {executeAction} from "./utils.js";
import TomSelect from "./tom-select.js";

$.fn.postFormAsAjax = async function() {
    const formData = new FormData(this[0]);
    const response = await fetch(this.attr("action"), {
      method: this.attr("method"),
      cache: 'no-cache',
      referrerPolicy: 'no-referrer',
      body: formData
    });
    return response.json();
  }

$(function () {
    // JSON FORMS
    $("body").on("submit", ".json-form", function (evt) {
        evt.preventDefault();
        $(this).postFormAsAjax().then(message => {
            if(message) executeAction(message);
        }).catch(error => console.log("Error posting form " + error));
    });

    // Ordered multiselects
    $(".tom-select").each( function(){
      const $input = $(this);
      const options_id = "#" + $input.data("select-options-id");
      const options = JSON.parse($(options_id).text());
      const select = new TomSelect($input[0], {
          plugins: ["remove_button", ],
          options: options,

      });
      const control = wrapper.find(".ts-control");
      const sortable = UIkit.sortable(control[0], { handle:"div" });
      UIkit.util.on(sortable.$el, 'moved', function (event, sortable, element) {
          let selected = Array();
          control.children("div").each( function (){
              selected.push($(this).data("value"));
          });
          $input.val(selected.join(","));
      });
  });
})
